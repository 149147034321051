import React from "react";
import { Col, Row } from "react-bootstrap";

import CommonLayout from "../../../layouts/CommonLayout";
import appStyles from "../../../App.module.css";
import { useSelector } from "react-redux";

import CarouselSlider from "../../../components/CarouselSlider/CarouselSlider";
import HomeSignUp from "../../../components/HomeSignUp/HomeSignUp";
import HotAlbumCardSm from "../../../components/AppCards/HotAlbumCardSm";
import SEOManager from "../../../utils/SEOManager";
import { GoogleAdSense, StaticImages } from "../../../utils/constants";
import GoogleAd from "../../../components/Advertisements/GoogleAd";
import HomeAboutUs from "../../../components/HomeAboutUs/HomeAboutUs";
import { getSEOConfig } from "../../../utils/seoConfig";

function HomePage() {
  const { topCharts, hotAlbums, isLoading, error } = useSelector(
    (state) => state.lyrics
  );

  const seoData = getSEOConfig("homePage", {});

  return (
    <>
      <CommonLayout>
        <SEOManager {...seoData} />

        {/* Carousel */}
        <div className="mt-2 mb-1">{/* <CarouselSlider /> */}</div>

        <div className="my-2">
          <HomeAboutUs />
        </div>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.home_1}
          />
        </div>

        {/* Top Charts */}
        <div className="my-2 p-2">
          <h2 className="text-uppercase fw-bold text-center mb-4">
            Top <span className={appStyles.textColorHeading}>Charts</span>
          </h2>

          <div>
            <Row>
              {topCharts &&
                topCharts.map((album, index) => (
                  <Col lg={4} md={4} sm={6} xs={12} key={index}>
                    <div className="p-2">
                      <HotAlbumCardSm
                        imageUrl={album?.image || StaticImages.App.LWStatic}
                        albumData={album}
                        type={"track"}
                      />
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </div>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.home_2}
          />
        </div>

        {/* Hot Albums */}
        <div className="my-2 p-2">
          {/* <HomeHotAlbum /> */}
          <h2 className="text-uppercase fw-bold text-center mb-4">
            Hot <span className={appStyles.textColorHeading}>Albums</span>
          </h2>

          <div>
            <Row>
              {hotAlbums &&
                hotAlbums.map((album, index) => (
                  <Col lg={4} md={4} sm={6} xs={12} key={index}>
                    <div className="p-2">
                      <HotAlbumCardSm
                        imageUrl={album?.image || StaticImages.App.LWStatic}
                        albumData={album}
                        type={"album"}
                      />
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </div>

        {/* Sign Up Image */}
        <div className="my-0 p-2">
          <HomeSignUp />
        </div>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.home_3}
          />
        </div>
      </CommonLayout>
    </>
  );
}

export default HomePage;

import React, { useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";

import AddToChromeCard from "../../../components/AppCards/AddToChromeCard";
import LyricsViewer from "../../../components/AppCards/LyricsViewer";
import { getConfig } from "../../../services/apiUtils";
import { lyricsController } from "../../../features/lyrics/lyricsSlice";
import SEOManager from "../../../utils/SEOManager";
import AppSpinner from "../../../components/Spinners/AppSpinner";
import CommentsList from "../../../components/AppLists/CommentsList";
import { StaticImages } from "../../../utils/constants";

import styles from "./LyricsPage.module.css";
import appStyles from "../../../App.module.css";
import { decodeFromUrl, formatForUrl } from "../../../utils/helpers";
import { ROUTES } from "../../../routes/constants";
import { getSEOConfig } from "../../../utils/seoConfig";

export const ads = [
  StaticImages.GoogleAds.Ad1,
  StaticImages.GoogleAds.Ad4,
  <AddToChromeCard />,
  StaticImages.GoogleAds.Ad17,
];

function LyricsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isrcKey, songName } = useParams();

  const { specificLyrics, isLoading, error } = useSelector(
    (state) => state.lyrics
  );

  console.log(specificLyrics);

  const getLyrics = useCallback(async () => {
    if (isrcKey) {
      try {
        await dispatch(
          lyricsController.getTrackLyrics({
            payload: { isrcKey },
            config: getConfig(),
            navigate,
          })
        ).unwrap();
      } catch (error) {
        console.error("Error Fetching Lyrics:", error);
      }
    }
  }, [dispatch, isrcKey, navigate]);

  useEffect(() => {
    getLyrics();

    const disablePrint = () => {
      window.print = () => console.log("Print functionality is disabled.");
    };

    const disableContextMenu = (event) => {
      event.preventDefault();
      console.log("Context menu is disabled.");
    };

    const disablePrintShortcut = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "p") {
        event.preventDefault();
        console.log("Print keyboard shortcut is disabled.");
      }
    };

    disablePrint();
    document.addEventListener("contextmenu", disableContextMenu);
    document.addEventListener("keydown", disablePrintShortcut);

    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
      document.removeEventListener("keydown", disablePrintShortcut);
    };
  }, [getLyrics]);

  const seoData = getSEOConfig("songPage", {
    songName: specificLyrics?.title || decodeFromUrl(songName),
    artistName: specificLyrics?.artist?.name || "Unknown",
    lyricsSnippet: specificLyrics?.lyrics?.substring(0, 165) || "",
    isrcKey,
    lyrics: specificLyrics?.lyrics || "",
  });

  return (
    <CommonLayout className={styles.printStyles_body}>
      {/* <SEOManager
        title={`${specificLyrics?.artist?.name || "Unknown"} - ${
          specificLyrics?.title || decodeFromUrl(songName)
        } Lyrics | LyricsWeb Lyrics`}
        description={`${
          specificLyrics?.title || decodeFromUrl(songName)
        } Lyrics: ${specificLyrics?.lyrics?.substring(0, 165)}`}
        keywords={`Lyrics, Lyrics of ${
          specificLyrics?.title || decodeFromUrl(songName)
        } by ${
          specificLyrics?.artist?.name || "Unknown"
        }, Song Lyrics, Lyrics Finder, Music Lyrics Search, Music Lyrics, Song Lyrics Search, Popular Song Lyrics, ${
          specificLyrics?.title || decodeFromUrl(songName)
        } Lyrics, lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics`}
        canonical={`https://www.lyricsweb.com/lyrics/${formatForUrl(
          specificLyrics?.title
        )}/${isrcKey}`}
        author={specificLyrics?.artist?.name || "Unknown"}
        language="en"
        ogTitle={`${specificLyrics?.title || decodeFromUrl(songName)} from ${
          specificLyrics?.artist?.name || "Unknown"
        } Lyrics - LyricsWeb`}
        ogDescription={`${
          specificLyrics?.title || decodeFromUrl(songName)
        } Lyrics: ${specificLyrics?.lyrics?.substring(0, 165)}`}
        ogImage="/favicon-32x32.png"
        ogUrl={`https://www.lyricsweb.com/lyrics/${formatForUrl(
          specificLyrics?.title
        )}/${isrcKey}`}
        ogType="article"
        ogLocale="en_US"
        structuredData={{
          "@context": "https://schema.org",
          "@type": "MusicComposition",
          name: `${specificLyrics?.title || decodeFromUrl(songName)}`,
          composer: {
            "@type": "Person",
            name: `${specificLyrics?.artist?.name || "Unknown"}`,
          },
          lyricist: {
            "@type": "Person",
            name: `${specificLyrics?.writer || "Unknown"}`,
          },
          lyrics: {
            "@type": "CreativeWork",
            text: `${specificLyrics?.lyrics}`,
          },
          inLanguage: `${specificLyrics?.language || "en"}`,
          isrcCode: `${isrcKey}`,
          sameAs: `https://lyricsweb.com/lyrics/${songName}/${isrcKey}`,
          // datePublished: "2024-08-18",
          // genre: "Pop",
          recordingOf: {
            "@type": "MusicRecording",
            name: `${specificLyrics?.title || decodeFromUrl(songName)}`,
            byArtist: {
              "@type": "MusicGroup",
              name: `${specificLyrics?.artist?.name || "Unknown"}`,
            },
          },
        }}
        robots="index, follow"
      /> */}

      <SEOManager {...seoData} />

      <div id="noPrint" className={styles.printStyles_noPrint}>
        <div className="my-2">
          <PageInfoHeader
            text={
              !specificLyrics?.title
                ? decodeFromUrl(songName)
                : specificLyrics?.title.toUpperCase()
            }
            imageUrl={StaticImages.Banner.LyricsDetails}
          />
        </div>

        <div>
          <Row className="m-0 p-0">
            <Col lg={9} md={9} sm={12} xs={12}>
              <h3 className="fw-bold my-4">
                {/* {songName.to}{" "} */}
                <span className={appStyles.textColorHeading}>Lyrics</span>
              </h3>
              {isLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "80vh" }}
                >
                  <AppSpinner size={60} />
                </div>
              ) : error ? (
                <div className="mt-4" style={{ minHeight: "80vh" }}>
                  <LyricsViewer
                    lyricsData={{
                      title: "No Song Available",
                      artist: "No Song Artist Available",
                      lyrics: "No Song Lyrics Available",
                    }}
                  />
                </div>
              ) : specificLyrics ? (
                <div className="mt-4">
                  <LyricsViewer lyricsData={specificLyrics} />
                </div>
              ) : (
                <div className="mt-5" style={{ minHeight: "80vh" }}>
                  <LyricsViewer
                    lyricsData={{
                      title: "No Song Available",
                      artist: "No Song Artist Available",
                      lyrics: "No Song Lyrics Available",
                    }}
                  />
                </div>
              )}

              <div className="my-4">
                <CommentsList />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </CommonLayout>
  );
}

export default LyricsPage;

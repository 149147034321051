import React from "react";
import { Card } from "react-bootstrap";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CommonLayout from "../../../layouts/CommonLayout";
import PageInfoHeader from "../../../components/PageInfoHeader/PageInfoHeader";
import {
  mobileRegex,
  passwordRegex,
  StaticImages,
} from "../../../utils/constants";
import appStyles from "../../../App.module.css";
import AppForm from "../../../components/AppForm/AppForm";
import { ROUTES } from "../../../routes/constants";
import { getConfig } from "../../../services/apiUtils";
import { authController } from "../../../features/auth/authSlice";
import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";

const signupFormFields = [
  {
    name: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "Enter your First Name",
  },
  {
    name: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "Enter your Last Name",
  },
  {
    name: "email",
    label: "Email Address",
    type: "email",
    placeholder: "Enter your Email Address",
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    placeholder: "Enter your Password",
  },
  {
    name: "mobile",
    label: "Mobile Number",
    type: "text",
    placeholder: "Enter your Mobile Number",
  },
];

const signupFormSchema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  lastName: yup.string().required("Last Name is Required"),
  email: yup.string().required("Email Address is Required"),
  password: yup
    .string()
    .required("Password is Required")
    .matches(
      passwordRegex,
      "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character"
    ),
  mobile: yup
    .string()
    .required("Mobile Number is Required")
    .matches(mobileRegex, "Mobile Number should be Numeric"),
});

function SignupPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);

  const onSubmit = async (data, resetForm) => {
    try {
      await dispatch(
        authController.createUser({
          payload: data,
          config: getConfig(),
          resetForm,
          navigate,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error Creating User:", error);
    } finally {
    }
  };

  const submitButtonProps = {
    btnTxt: "Sign Up",
    className: `${appStyles.appDefaultBtn} ${
      isLoading ? appStyles.appDefaultBtnDisabled : ""
    } w-50`,
    isLoading: isLoading,
    isLoadingText: "Loading...",
  };

  const cancelButtonProps = {
    btnTxt: "Login",
    className: `${appStyles.appDefaultBtnDark} w-50`,
    onClick: () => navigate(ROUTES.AUTH.LOGIN),
  };

  const seoData = getSEOConfig("signupPage");

  return (
    <>
      <CommonLayout>
        <SEOManager {...seoData} />

        {/* Page Info Header */}
        <div className="my-2">
          <PageInfoHeader text="Sign Up" imageUrl={StaticImages.Banner.News} />
        </div>
        <div className="my-5">
          <Card
            className={`p-5`}
            style={{ backgroundColor: "var(--appColor5)" }}
          >
            <Card.Text as={"h1"} className="fw-bold text-uppercase">
              <div>
                Welcome to the{" "}
                <span className={`${appStyles.textColorHeading}`}>Content</span>
              </div>
              <div className={`${appStyles.textColorHeading}`}>
                Management Hub
              </div>
            </Card.Text>
            <Card.Subtitle as={"h6"}>
              Sign Up to unlock the power of content management. Gain access to
              your account settings, securely update your password, and dive
              into the world of content creation and curation.
            </Card.Subtitle>
            <div className="my-5">
              <AppForm
                fields={signupFormFields}
                validationSchema={signupFormSchema}
                onSubmit={onSubmit}
                submitButtonProps={submitButtonProps}
                cancelButtonProps={cancelButtonProps}
              />
            </div>
          </Card>
        </div>
      </CommonLayout>
    </>
  );
}

export default SignupPage;
